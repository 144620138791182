<template>
  <div>
    <div class="text-center pb-2">
      <span :class="`display-1 font-weight-bold`" style="border-bottom: solid .5px">
        Pitch Detection
      </span>
    </div>
    <v-row dense align="center" justify="center">
      <v-col :cols="12">
        <v-divider/>
      </v-col>
      <v-col cols="12">
        <pitch-detector :should-capture="isCapturingUserMedia"/>
      </v-col>
      <v-col>
        <piano/>
      </v-col>
      <v-col :cols="12" class="py-12 action-btn" style="position: sticky; bottom: 0;">
        <v-btn block :color="isCapturingUserMedia ? 'warning' : 'success darken-3'"
               @click="isCapturingUserMedia = !isCapturingUserMedia">
          Turn {{ isOn }}
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import PitchDetector from 'src/sections/PitchDetector'

export default {
  name:       'Detector',
  components: {
    PitchDetector,
    Piano: () => import('framework/ui/components/piano/AppPiano'),
  },

  data() {
    return {
      isCapturingUserMedia: true,
    }
  },

  computed: {
    isOn: function() {
      return this.isCapturingUserMedia ? 'Off' : 'On'
    },
  },
}
</script>

<style lang="scss">
  .action-btn{
    z-index: 5;
  }
</style>